import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "card-header" }
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "card-toolbar" }
const _hoisted_4 = { class: "text-gray-400 fw-bold fs-7" }
const _hoisted_5 = { class: "card-body fs-5" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "example-pagination-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newsItem, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "card mb-9",
        key: item.news_id
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h3", {
            class: "card-title",
            id: item.news_id
          }, _toDisplayString(_ctx.$i18n.locale == 'hk' ? item.title : item.title_en), 9, _hoisted_2),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(item.updatetime), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", {
            innerHTML: _ctx.$i18n.locale == 'hk' ? item.msg : item.msg_en,
            class: "mx-3 mx-sm-0"
          }, null, 8, _hoisted_6)
        ])
      ]))
    }), 128)),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_el_pagination, {
        layout: "prev, pager, next",
        "page-count": _ctx.maxPage,
        currentPage: _ctx.currentPage,
        "onUpdate:currentPage": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentPage) = $event)),
        background: ""
      }, null, 8, ["page-count", "currentPage"])
    ])
  ], 64))
}